﻿// Libraries
import m from 'mithril'
import { times, map, find, forEach } from 'lodash'

// Models
import User from '../models/User'
import Subgroup from '../models/Subgroup'

// Components
import { card } from 'bootstrap-mithril'
import formGroup from '../components/formGroup'
import input from '../components/input'
import saveBtnSubmit from '../components/saveBtnSubmit'
import loading from '../components/loading'
import { actionbar } from '../components/actionbar'
import Choices from 'choices.js'
import dragula from 'dragula'

var pageUser = {}

pageUser.oninit = (vnode) => {
	vnode.state.choices = []
	vnode.state.draggingPage = false
	vnode.state.answers = []
	User.get(vnode.attrs.user().id).then(() => {
		if (User.current().surveyAnswersDI) {
			User.current().surveyAnswersDI.answers.forEach((element) => {
				vnode.state.answers[element.questionId] = JSON.parse(element.value)
			})
		}
		User.getConfig()
		Subgroup.getList()
		User.getSurvey(228).then(() => {
			if (User.current().surveyAnswersDI) {
				User.current().surveyAnswersDI.survey = User.survey()
			} else {
				User.current().surveyAnswersDI = {
					answers: [],
					survey: User.survey(),
				}
			}
		})
	})
}

const setFormAnswer = (question, val) => {
	User.current().surveyAnswersDI.answers.find((a) => {
		return a.questionId === question.questionId
	}).value = JSON.stringify(val)
	m.redraw()
}

const getFormAnswer = (questionId) => {
	return User.current().surveyAnswersDI.answers.find((a) => {
		return a.questionId === questionId
	}) &&
		User.current().surveyAnswersDI.answers.find((a) => {
			return a.questionId === questionId
		}).value
		? JSON.parse(
				User.current().surveyAnswersDI.answers.find((a) => {
					return a.questionId === questionId
				}).value
		  )
		: ''
}

const isFormAnswerMatched = (question) => {
	const answer = getFormAnswer(question.visibleQuestionId)
	console.log(answer)
	let response = false
	if (answer) {
		const matchValue = question.visibleQuestionValue

		if ((Array.isArray(answer) && answer.includes(matchValue)) || answer === matchValue) {
			response = true
		} else if (matchValue.indexOf('||')) {
			let matchValueArr = matchValue.split('||')
			matchValueArr.forEach((element) => {
				if ((Array.isArray(answer) && answer.includes(element)) || answer === element) {
					response = true
				}
			})
		}
	}
	return response
}

function setSurveyAnswers(answers) {
	// User.current().surveyAnswersDI = {
	// 	surveyId: 140,
	// 	survey: User.survey(),
	// 	surveyAnswersId: User.current().surveyAnswersDIId ? User.current().surveyAnswersDIId : 0,
	// 	answers: map(Object.keys(answers), (a) => {
	// 		return {
	// 			questionId: a,
	// 			value: JSON.stringify(answers[a]),
	// 		}
	// 	}),
	//}
	m.redraw()
}

pageUser.view = (vnode) => {
	return User.current() && User.config() && actionbar
		? [
				m(actionbar, {
					breadcrumbs: [
						{
							text: 'Account',
						},
						{
							text: 'Voting preferences',
						},
					],
					buttons: [],
				}),
				m(
					'form.container-fluid',
					{
						onsubmit: (e) => {
							e.preventDefault()

							User.save()
						},
					},
					[
						m('.row.main-content', [
							User.config().isSubgroupRegVisible
								? m('.col-sm-12', [
										m(card, {
											header: 'Step 1.',
											body: [
												m('h4', 'Do you think that you will have time to commit to voting for BIFA this year?'),
												m(
													'p',
													'On average, we ask voters to watch about 20 - 25 feature films or 30 - 40 shorts from early summer to November.'
												),
												m('.custom-control.custom-switch.custom-control-inline.custom-control-input-success', [
													m('input.custom-control-input', {
														type: 'radio',
														id: 'i-yes',
														name: 's-ns-c',
														checked: User.current().isAvailable,
														onchange: (e) => {
															User.current().isAvailable = (e.currentTarget || e.target).checked
															//VoterFilm.save()
														},
													}),
													m(
														'label.custom-control-label',
														{
															for: 'i-yes',
														},
														'Yes'
													),
												]),
												m('.custom-control.custom-switch.custom-control-inline', [
													m('input.custom-control-input', {
														type: 'radio',
														id: 'i-no',
														name: 's-ns-c',
														checked: User.current().isAvailable === false,
														onchange: (e) => {
															User.current().isAvailable = !(e.currentTarget || e.target).checked
															//VoterFilm.save()
														},
													}),
													m(
														'label.custom-control-label',
														{
															for: 'i-no',
														},
														'No'
													),
												]),
											],
										}),
										m('hr'),
										User.current().isAvailable &&
											m('div', [
												m(card, {
													// Step 2. Preferences
													header: 'Step 2. Preferences',
													body: [
														m(
															'p',
															'Please select, in order of preference (with 1 being your most desirable and 10 being your least desirable), which subgroups you would be interested in being a part of. You may indicate your preference for as many or as few subgroups as you wish. Remember that by joining a subgroup you are committing to watching a large number of films in that category but are free to watch films in other categories as well if you want to.'
														),
														m('.row', [
															m('.col-md-6', [
																m(
																	'ul.list-group.list-group-flush.votes',
																	{
																		oncreate: (votes) => {
																			let drake = dragula([votes.dom], {
																				moves: function (el, container, handle) {
																					return handle.classList.contains('handle')
																				},
																			})
																			drake.on('drag', function () {
																				vnode.state.draggingPage = true
																				m.redraw()
																			})
																			drake.on('dragend', function () {
																				vnode.state.draggingPage = false
																				m.redraw()
																			})
																			drake.on('drop', () => {
																				forEach(votes.dom.children, (el, ix) => {
																					let existvote = find(User.current().requestedSubgroups, (s) => {
																						return s.Preference === ix + 1
																					})
																					el.children[0].children[0].innerText = ix + 1
																					if (existvote) existvote.subgroupId = el.dataset.entryid
																					else
																						User.current().requestedSubgroups.push({
																							SubgroupId: el.dataset.entryid,
																							Preference: ix + 1,
																						})
																				})
																			})
																		},
																	},
																	[
																		times(10, (ix) => {
																			let existingVote = find(User.current().requestedSubgroups, (s) => {
																				return s.Preference === ix + 1
																			})
																			return m(
																				'li.list-group-item.d-flex.p-1',
																				{
																					key: `pref_${ix}`,
																					'data-entryid': existingVote ? existingVote.subgroupId : 0,
																				},
																				[
																					m('.d-flex.align-items-center.p-2.handle', [
																						m('span.badge.badge-info.handle', ix + 1),
																					]),
																					Subgroup.list()
																						? m('select', {
																								id: `sel_${ix + 1}`,
																								key: `sel_${ix + 1}`,
																								name: `sel_${ix + 1}`,
																								className: 'choices__input is-hidden',
																								oncreate: (el) => {
																									const choices = new Choices(el.dom, {
																										searchEnabled: true,
																										shouldSort: true,
																										removeItemButton: true,
																										choices: [{ value: null, label: null }],
																									})
																									vnode.state.choices.push(choices)
																									choices.setChoices(
																										getChoices(User.current().requestedSubgroups, ix),
																										'value',
																										'label',
																										false
																									)
																								},
																								onchange: (evt) => {
																									let existvote = find(User.current().requestedSubgroups, (pref) => {
																										return pref.preference === ix + 1
																									})
																									let val = evt.target.value === '' ? 0 : evt.detail.value
																									if (existvote) existvote.subgroupId = val
																									else {
																										if (!User.current().requestedSubgroups) {
																											User.current().requestedSubgroups = []
																										}
																										User.current().requestedSubgroups.push({
																											subgroupId: val,
																											preference: ix + 1,
																										})
																									}

																									times(10, (ix) => {
																										vnode.state.choices[ix].setChoices(
																											getChoices(User.current().requestedSubgroups, ix),
																											'value',
																											'label',
																											true
																										)
																									})
																									m.redraw()
																								},
																						  })
																						: m(loading),
																				]
																			)
																		}),
																	]
																),
																m('.mt-4', [
																	m('h5.font-weight-bold', 'Meeting Information'),
																	m(
																		'p',
																		'All meetings will take place via Zoom and Subgroup meetings last approximately 90 minutes. You are strongly encouraged to make time to attend all your subgroup meetings to ensure you are involved in voting discussions and decisions. Only voters who have attended at least 2 of the 3 meetings will be invited to attend the BIFA ceremony afterparty on Sunday 3 December.'
																	),

																	m('p.mt-4.font-weight-bold', '2023 Voter Briefing (60 mins) Wed 12th July 10-11am'),
																	m(
																		'p.font-italic',
																		'All voters are invited to attend this refresher on the voting process. This meeting is recorded and will be made available after the briefing'
																	),
																]),
															]),
															m('.col-md-6', [
																m('h5.font-weight-bold', 'Subgroup Meeting Dates and Times'),
																m('ul.list-group', [
																	Subgroup.list()
																		? map(Subgroup.list(), (sg) => {
																				return m('li.list-group-item.d-flex', [
																					m('div.w-50.pr-4', [
																						m('strong', sg.name),
																						m('br'),
																						m('span', { style: 'font-size:0.8rem' }, sg.description),
																					]),
																					m(
																						'div.w-50',
																						{ style: 'font-size:0.8rem' },
																						m.trust(sg.meetings ? sg.meetings.replaceAll('\n', '<br>') : '')
																					),
																				])
																		  })
																		: m(loading),
																]),
															]),
														]),
													],
												}),
												m('hr'),
												m(card, {
													// Step 3. Info
													header: 'Step 3. Your information',
													body: [
														m(
															'p',
															'Below is the contact information we currently have on record for you. Please update this if it is not correct.'
														),
														m(formGroup, {
															label: 'Email for BIFA correspondence',
															type: 'textfield',
															resource: User,
															property: 'EmailForBifaInfo',
															subtype: 'email',
															required: false,
														}),
														m(formGroup, {
															label: 'Email for distributor correspondence',
															type: 'textfield',
															resource: User,
															property: 'EmailForVotingInfo',
															subtype: 'email',
															required: false,
														}),
														m(formGroup, {
															label: 'Town/City',
															type: 'textfield',
															resource: User,
															property: 'TownCity',
															required: true,
														}),
														m(formGroup, {
															label: 'Country',
															type: 'textfield',
															resource: User,
															property: 'Country',
															required: true,
														}),
														m(formGroup, {
															label: 'Please tell us your current occupation (eg Director, Producer, Casting Director)',
															type: 'textfield',
															resource: User,
															property: 'Occupation',
															required: true,
														}),
														m(formGroup, {
															label: 'Is your experience mainly in',
															type: 'select',
															resource: User,
															property: 'Experience',
															required: true,
															choices: [
																{ label: 'Select one...', value: '' },
																{ label: 'Fiction Film', value: 'Fiction Film' },
																{ label: 'Documentary Film', value: 'Documentary Film' },
																{
																	label: 'Equally both Fiction and Documentary film',
																	value: 'Equally both Fiction and Documentary film',
																},
																{
																	label: 'Not applicable (I don’t work in production or distribution)',
																	value: 'Not applicable (I don’t work in production or distribution)',
																},
															],
														}),
														m(
															'p',
															'We may also require a mobile number for you to use for certain secure screener login processes for some distributors. If you are happy to share this with us and the distributors, please enter your mobile number here*'
														),
														m('.row', [
															m('.col-md-6.col-lg-4', [
																m(formGroup, {
																	label: 'Mobile',
																	type: 'textfield',
																	resource: User,
																	property: 'Mobile',
																}),
															]),
														]),
														m(
															'p.text-muted.small',
															'*Your mobile number will only be used as part of the secure login process for certain online screening services, who sign data agreements with BIFA regarding the use of such information.'
														),
													],
												}),
												m('hr'),
												m(card, {
													// Step 4. Agreements
													header: 'Step 4. Agreements',
													body: [
														User.config() &&
															m(
																'p',
																m.trust(
																	`By ticking this box and registering to be a voter for the British Independent Film Awards 2023, all voters agree to abide by BIFA's Voter Agreement and Code of Conduct which can be found in full <a href='${
																		User.config().VoterAgreementLink
																			? User.config().VoterAgreementLink
																			: 'https://storage.googleapis.com/bifa-film/web/2022/05/BIFA-Voter-Agreement-2022.pdf'
																	}' target='_blank'>here</a>.`
																)
															),

														m('.custom-control.custom-switch.custom-control-inline', [
															m('input.custom-control-input.custom-control-input-success', {
																type: 'checkbox',
																id: 'i-IsAgreeTerms',
																checked: User.current().isAgreeTerms,
																required: true,
																onchange: (e) => {
																	User.current().isAgreeTerms = (e.currentTarget || e.target).checked
																},
															}),
															m(
																'label.custom-control-label',
																{
																	for: 'i-IsAgreeTerms',
																},
																'I agree'
															),
														]),

														m('p.mt-4', 'Also, please confirm that'),
														m('div', [
															m('.custom-control.custom-switch.custom-control-inline', [
																m('input.custom-control-input.custom-control-input-success', {
																	type: 'checkbox',
																	id: 'i-IsBIFAContactConsent',
																	checked: User.current().isBIFAContactConsent,
																	onchange: (e) => {
																		User.current().isBIFAContactConsent = (e.currentTarget || e.target).checked
																	},
																}),
																m(
																	'label.custom-control-label',
																	{
																		for: 'i-IsBIFAContactConsent',
																	},
																	'I am happy to receive information about BIFA voting from BIFA using the details given above.'
																),
															]),
															// m('.custom-control.custom-switch.custom-control-inline', [
															// 	m('input.custom-control-input.custom-control-input-success', {
															// 		type: 'checkbox',
															// 		id: 'i-IsDistributorContactConsent',
															// 		checked: User.current().isDistributorContactConsent,
															// 		onchange: e => {
															// 			User.current().isDistributorContactConsent = (e.currentTarget || e.target).checked
															// 		},
															// 	}),
															// 	m(
															// 		'label.custom-control-label',
															// 		{
															// 			for: 'i-IsDistributorContactConsent',
															// 		},
															// 		'I am happy for BIFA to pass my contact details on to distributors who have signed data use agreements with BIFA, for the purposes of receiving screeners and invitations to screenings.'
															// 	),
															// ]),
															m('.custom-control.custom-switch.custom-control-inline', [
																m('input.custom-control-input.custom-control-input-success', {
																	type: 'checkbox',
																	id: 'i-IsBIFANewsletterConsent',
																	checked: User.current().isBIFANewsletterConsent,
																	onchange: (e) => {
																		User.current().isBIFANewsletterConsent = (e.currentTarget || e.target).checked
																	},
																}),
																m(
																	'label.custom-control-label',
																	{
																		for: 'i-IsBIFANewsletterConsent',
																	},
																	'I would like to receive the BIFA newsletter, to learn more about BIFA activities/events, partner offers, industry opportunities and the latest in independent film news.'
																),
															]),
															m('.custom-control.custom-switch.custom-control-inline', [
																m('input.custom-control-input.custom-control-input-success', {
																	type: 'checkbox',
																	id: 'i-IsBIFAResearch',
																	checked: User.current().isBIFAResearch,
																	onchange: (e) => {
																		User.current().isBIFAResearch = (e.currentTarget || e.target).checked
																	},
																}),
																m(
																	'label.custom-control-label',
																	{
																		for: 'i-IsBIFAResearch',
																	},
																	'I am happy for BIFA to use my data to run analyses and research on the demographics and state of the British independent film industry.'
																),
															]),
															m(
																'p.mt-3',
																m.trust(
																	"We've updated our privacy policies so that we're GDPR compliant. You can read our privacy policy regarding information you give to us for the purposes of voting for BIFA <a href='https://www.bifa.film/privacy-policy/voting/' target='_blank'>here</a>."
																)
															),
														]),
														m('.custom-control.custom-switch.custom-control-inline', [
															m('input.custom-control-input.custom-control-input-success', {
																type: 'checkbox',
																id: 'i-IsMoreEDICoursesInfo',
																checked: User.current().isMoreEDICoursesInfo,
																onchange: (e) => {
																	User.current().isMoreEDICoursesInfo = (e.currentTarget || e.target).checked
																},
															}),
															m(
																'label.custom-control-label',
																{
																	for: 'i-IsMoreEDICoursesInfo',
																},
																"I would like to receive more information about BIFA's Equality, Diversity & Inclusion training courses, including Anti-Bullying and Harassment Training, Unconscious Bias and Fair Recruitment and Inclusivity in the Workplace Training."
															),
														]),
													],
												}),
												m('hr'),

												m(card, {
													// Step 5. Email Consent
													header: 'Step 5. Consent',
													body: [
														m('p', 'Eventive'),
														m(
															'p',
															'BIFA uses Eventive as a screening platform for the awards. To access any BIFA film online on Eventive you must have a BIFA pass, which is allocated to you via the Eventive platform using your name and email address. If you used Eventive to view BIFA films online in 2022 and the email address you have provided above is unchanged, your BIFA pass will be added to your Eventive account and you will be notified of this by email. If you do not have an Eventive account, you will be prompted to create one when your pass is issued.'
														),
														m('.custom-control.custom-switch.custom-control-inline.custom-control-input-success', [
															m('input.custom-control-input', {
																type: 'radio',
																id: 'i-eventiveconsentyes',
																name: 'ecc',
																checked: User.current().isAgreeEventive,
																required: true,
																onchange: (e) => {
																	User.current().isAgreeEventive = (e.currentTarget || e.target).checked
																	//VoterFilm.save()
																},
															}),
															m(
																'label.custom-control-label',
																{
																	for: 'i-eventiveconsentyes',
																},
																'I CONSENT to BIFA using my name and email address as provided to allocate my BIFA pass on Eventive'
															),
														]),
														m('.custom-control.custom-switch.custom-control-inline', [
															m('input.custom-control-input', {
																type: 'radio',
																id: 'i-eventiveconsentno',
																name: 'ecc',
																checked: User.current().isAgreeEventive === false,
																required: true,
																onchange: (e) => {
																	User.current().isAgreeEventive = !(e.currentTarget || e.target).checked
																	//VoterFilm.save()
																},
															}),
															m(
																'label.custom-control-label',
																{
																	for: 'i-eventiveconsentno',
																},
																'I DO NOT CONSENT to BIFA using my name and email address as provided to allocate my BIFA pass on Eventive'
															),
														]),
														m(
															'p.font-italic',
															m(
																'a',
																{ href: 'https://eventive.org/privacy', target: '_blank' },
																"If you would like to read Eventive's privacy policy click here"
															)
														),
														m('p.mt-4', 'Screener Requests'),
														m(
															'p',
															'Occasionally we need to send the contact details you provide to distributors for the purpose of providing you with individual access or links to films in contention in the awards.'
														),
														m('.custom-control.custom-switch.custom-control-inline.custom-control-input-success', [
															m('input.custom-control-input', {
																type: 'radio',
																id: 'i-emailconsentyes',
																name: 'ec',
																checked: User.current().isDistributorContactConsent,
																required: true,
																onchange: (e) => {
																	User.current().isDistributorContactConsent = (e.currentTarget || e.target).checked
																	//VoterFilm.save()
																},
															}),
															m(
																'label.custom-control-label',
																{
																	for: 'i-emailconsentyes',
																},
																'I CONSENT to BIFA passing my contact details (email address, name, phone number) on to distributors, for the purposes of receiving screeners and invitations to screenings'
															),
														]),
														m('.custom-control.custom-switch.custom-control-inline', [
															m('input.custom-control-input', {
																type: 'radio',
																id: 'i-emailconsentno',
																name: 'ec',
																checked: User.current().isDistributorContactConsent === false,
																required: true,
																onchange: (e) => {
																	User.current().isDistributorContactConsent = !(e.currentTarget || e.target).checked
																	//VoterFilm.save()
																},
															}),
															m(
																'label.custom-control-label',
																{
																	for: 'i-emailconsentno',
																},
																'I DO NOT CONSENT to BIFA passing my contact details (email address, name, phone number) on to distributors, for the purposes of receiving screeners and invitations to screenings'
															),
														]),
														m(
															'p.mt-3',
															'For strictly the above purpose only, occasionally this data needs to be sent outside of the UK, including to the US.'
														),
														m('.custom-control.custom-switch.custom-control-inline.custom-control-input-success', [
															m('input.custom-control-input', {
																type: 'radio',
																id: 'i-emailconsentusyes',
																name: 'ecus',
																checked: User.current().isDistributorContactOutsideUKConsent,
																required: true,
																onchange: (e) => {
																	User.current().isDistributorContactOutsideUKConsent = (
																		e.currentTarget || e.target
																	).checked
																	//VoterFilm.save()
																},
															}),
															m(
																'label.custom-control-label',
																{
																	for: 'i-emailconsentusyes',
																},
																'I CONSENT to my contact details (email address, name, phone number) being sent to distributors, where necessary, outside of the UK, including to the US'
															),
														]),
														m('.custom-control.custom-switch.custom-control-inline', [
															m('input.custom-control-input', {
																type: 'radio',
																id: 'i-emailconsentusno',
																name: 'ecus',
																checked: User.current().isDistributorContactOutsideUKConsent === false,
																required: true,
																onchange: (e) => {
																	User.current().isDistributorContactOutsideUKConsent = !(e.currentTarget || e.target)
																		.checked
																	//VoterFilm.save()
																},
															}),
															m(
																'label.custom-control-label',
																{
																	for: 'i-emailconsentusno',
																},
																'I DO NOT CONSENT to my contact details (email address, name, phone number) being sent to distributors, where necessary, outside of the UK, including to the US'
															),
														]),
														m(
															'p.font-italic',
															'Every distributor who handles your data is required to agree to handle voter data in line with the principles of GDPR.  Distributors will dispose of your information once awards season is over.'
														),
														m(
															'p.font-italic',
															'Please be advised that not giving consent will mean that we may not always be able to give you access to the films you wish to view.'
														),
														m(
															'p.font-italic',
															'You may withdraw your consent at any time (by emailing hello@bifa.film)'
														),
													],
												}),
												m('hr'),

												m(card, {
													// Step 6. Conflicts of interest
													header: 'Step 6. Conflicts of interest',
													body: [
														m(
															'p',
															'As part of the agreement to register to vote for BIFA, we ask you to declare any conflict of interest you might have. We consider a COI to be:'
														),
														m('ul', [
															m('li', 'if you worked on a film directly and have a credit'),
															m('li', 'currently are employed by a company who funded or distributed the film'),
															m('li', 'have a partner or relative who worked on the film.'),
														]),
														m(
															'p.font-weight-bold',
															'If you recognise a name or are acquainted with that individual, this does not necessarily constitute a COI. Of course, if you feel you can’t be impartial, please mark it as a conflict when voting.'
														),
														m('p', 'At this stage, could you please just let us know the following:'),
														m(formGroup, {
															label: 'Your current employer/employers',
															type: 'textarea',
															resource: User,
															property: 'currentEmployers',
															required: false,
															rows: 3,
															classname: 'mb-0',
														}),
														m('p.small', 'This can include in either full-time, part-time or freelance capacities.'),
														m(formGroup, {
															label: 'Productions you have worked on in any capacity, in the last two years.',
															type: 'textarea',
															resource: User,
															property: 'productionsLast2Years',
															required: false,
															rows: 3,
														}),
														m(formGroup, {
															label: 'If you have one, please provide your IMDB or Linkedin profile link',
															type: 'textfield',
															resource: User,
															property: 'imdbLinkedIn',
															required: false,
														}),
													],
												}),
												m('hr'),

												m(card, {
													// Step 7. Unconscious Bias Training
													header: 'Step 7. Unconscious Bias Training',
													body: [
														m(
															'p',
															"As a BIFA voter, you're required to attend our Unconscious Bias training every three voting years. If you did the training in 2019, or have not yet done the training, please ensure you register for a session."
														),
														m(
															'label.w-50.mb-0.pr-3',
															{ for: `sel_ub` },
															'Please select when you last did the training:'
														),
														m('p', { style: 'max-width: 320px' }, [
															m(
																'select',
																{
																	id: `sel_ub`,
																	key: `sel_ub`,
																	name: `sel_ub`,
																	className: 'form-control',
																	required: true,
																	onchange: (evt) => {
																		User.current().unconsciousBiasStatus = (evt.currentTarget || evt.target).value
																		User.current().isUnconsciousBiasTrainingComplete =
																			User.current().unconsciousBiasStatus === '2020 voting year' ||
																			User.current().unconsciousBiasStatus === '2021 voting year' ||
																			User.current().unconsciousBiasStatus === '2022 voting year'
																	},
																},
																[
																	m('option', { value: '' }, 'Select...'),
																	m(
																		'option',
																		{
																			value: '2019 voting year',
																			selected:
																				User.current().unconsciousBiasStatus === '2019 voting year' ? 'selected' : null,
																		},
																		'2019 voting year'
																	),
																	m(
																		'option',
																		{
																			value: '2020 voting year',
																			selected:
																				User.current().unconsciousBiasStatus === '2020 voting year' ? 'selected' : null,
																		},
																		'2020 voting year'
																	),
																	m(
																		'option',
																		{
																			value: '2021 voting year',
																			selected:
																				User.current().unconsciousBiasStatus === '2021 voting year' ? 'selected' : null,
																		},
																		'2021 voting year'
																	),
																	m(
																		'option',
																		{
																			value: '2022 voting year',
																			selected:
																				User.current().unconsciousBiasStatus === '2022 voting year' ? 'selected' : null,
																		},
																		'2022 voting year'
																	),
																	m('option', { value: 'Not sure' }, 'Not sure'),
																	m('option', { value: 'Never' }, 'Never'),
																]
															),
														]),
													],
												}),
												m('hr'),

												m(card, {
													// Step 8. Diversity & Inclusion Monitoring
													header: 'Step 8. Diversity & Inclusion Monitoring',
													body: [
														User.survey() && m.trust(User.survey().Description),
														User.survey() &&
															m('ol.list-group.col-md-9.col-lg-8', [
																map(User.survey().Questions, (question) => {
																	return question.visibleQuestionId === null || isFormAnswerMatched(question)
																		? m(
																				'li.list-group-item.p-2',
																				{
																					className:
																						question.visiblequestionId &&
																						!(
																							vnode.state.answers[question.visiblequestionId] ===
																							question.visibleQuestionValue
																						)
																							? 'd-none'
																							: '',
																				},
																				[
																					question.questionType.id === 3 &&
																						m('.form-group.mb-0.d-flex', [
																							m('label.w-50.mb-0.pr-3', { for: `sel_q_${question.questionId}` }, [
																								question.name,
																								question.isRequired && ' *',
																							]),
																							m(
																								'select.w-50.mb-0',
																								{
																									id: `sel_q_${question.questionId}`,
																									name: `sel_q_${question.questionId}`,
																									key: `sel_q_${question.questionId}`,
																									required:
																										question.isRequired &&
																										!(
																											question.visiblequestionId &&
																											!(
																												vnode.state.answers[question.visiblequestionId] ===
																												question.visibleQuestionValue
																											)
																										),
																									className: 'form-control',
																									onchange: (evt) => {
																										//User.current().SurveyAnswersDI.Answers[] = evt.detail.value
																										vnode.state.answers[question.questionId] = (
																											evt.currentTarget || evt.target
																										).value
																										setSurveyAnswers(vnode.state.answers)
																									},
																								},
																								[
																									m('option', { value: '' }, 'Select...'),
																									map(question.questionOptions, (option) => {
																										return m(
																											'option',
																											{
																												value: option.Name,
																												selected:
																													vnode.state.answers[question.questionId] === option.Name,
																											},
																											option.Name
																										)
																									}),
																								]
																							),
																						]),
																					question.questionType.id === 1 &&
																						m('.form-group.mb-0.d-flex', [
																							m('label.w-50.mb-0.pr-3', { for: `sel_q_${question.questionId}` }, [
																								question.name,
																								question.isRequired && ' *',
																							]),
																							m('input.form-control.mb-0', {
																								id: `sel_q_${question.questionId}`,
																								type: 'text',
																								name: `sel_q_${question.questionId}`,
																								required:
																									question.isRequired &&
																									!(
																										question.visiblequestionId &&
																										!(
																											vnode.state.answers[question.visiblequestionId] ===
																											question.visibleQuestionValue
																										)
																									),
																								onkeyup: (event) => {
																									vnode.state.answers[question.questionId] = (
																										event.currentTarget || event.target
																									).value
																									setSurveyAnswers(vnode.state.answers)
																								},
																								onchange: (event) => {
																									vnode.state.answers[question.questionId] = (
																										event.currentTarget || event.target
																									).value
																									setSurveyAnswers(vnode.state.answers)
																								},
																								value: vnode.state.answers[question.questionId] || '',
																							}),
																						]),
																					question.questionType.id === 2 &&
																						m('.form-group.mb-0.d-flex', [
																							m('label.w-50.mb-0.pr-3', { for: `sel_q_${question.questionId}` }, [
																								question.name,
																							]),
																							m('textarea.form-control.mb-0', {
																								id: `sel_q_${question.questionId}`,
																								name: `sel_q_${question.questionId}`,
																								rows: '4',
																								required:
																									question.isRequired &&
																									!(
																										question.visiblequestionId &&
																										!(
																											vnode.state.answers[question.visiblequestionId] ===
																											question.visibleQuestionValue
																										)
																									),
																								onkeyup: (event) => {
																									vnode.state.answers[question.questionId] = (
																										event.currentTarget || event.target
																									).value
																									setSurveyAnswers(vnode.state.answers)
																								},
																								onchange: (event) => {
																									vnode.state.answers[question.questionId] = (
																										event.currentTarget || event.target
																									).value
																									setSurveyAnswers(vnode.state.answers)
																								},
																								value: vnode.state.answers[question.questionId] || '',
																							}),
																						]),
																				]
																		  )
																		: null
																}),
															]),
													],
												}),
												m('hr'),
											]),

										m(card, {
											header: 'Save',
											body: [
												m('p', 'Please click "Save changes" to update your settings'),
												m('.row', [m('.col-sm-6.col-md-3.col-lg-2', m(saveBtnSubmit, { resource: User }))]),
											],
										}),
								  ])
								: m('.col-sm-12', [
										m(card, {
											header: 'Step 1: Winners Voting',
											body: [
												m('h4', 'Would you like to take part in winners voting?'),
												m('.custom-control.custom-switch.custom-control-inline.custom-control-input-success', [
													m('input.custom-control-input', {
														type: 'radio',
														id: 'i-yes',
														name: 's-ns-c',
														checked: User.current().isWinnersVoting,
														onchange: (e) => {
															User.current().isWinnersVoting = (e.currentTarget || e.target).checked
															//VoterFilm.save()
														},
													}),
													m(
														'label.custom-control-label',
														{
															for: 'i-yes',
														},
														'Yes'
													),
												]),
												m('.custom-control.custom-switch.custom-control-inline', [
													m('input.custom-control-input', {
														type: 'radio',
														id: 'i-no',
														name: 's-ns-c',
														checked: User.current().isWinnersVoting === false,
														onchange: (e) => {
															User.current().isWinnersVoting = !(e.currentTarget || e.target).checked
															//VoterFilm.save()
														},
													}),
													m(
														'label.custom-control-label',
														{
															for: 'i-no',
														},
														'No'
													),
												]),
											],
										}),

										m('hr'),
										User.current().isWinnersVoting &&
											m('div', [
												m('hr'),
												m(card, {
													// Step 2. Info
													header: 'Step 2. Your information',
													body: [
														m(
															'p',
															'Below is the contact information we currently have on record for you. Please update this if it is not correct.'
														),
														m(formGroup, {
															label: 'Email for BIFA correspondence',
															type: 'textfield',
															resource: User,
															property: 'emailForBifaInfo',
															subtype: 'email',
															required: false,
														}),
														m(formGroup, {
															label: 'Email for distributor correspondence',
															type: 'textfield',
															resource: User,
															property: 'emailForVotingInfo',
															subtype: 'email',
															required: false,
														}),
														m(formGroup, {
															label: 'Town/City',
															type: 'textfield',
															resource: User,
															property: 'townCity',
															required: true,
														}),
														m(formGroup, {
															label: 'Country',
															type: 'textfield',
															resource: User,
															property: 'country',
															required: true,
														}),
														m(formGroup, {
															label: 'Please tell us your current occupation (eg Director, Producer, Casting Director)',
															type: 'textfield',
															resource: User,
															property: 'occupation',
															required: true,
														}),
														m(formGroup, {
															label: 'Is your experience mainly in',
															type: 'select',
															resource: User,
															property: 'experience',
															required: true,
															choices: [
																{ label: 'Select one...', value: '' },
																{
																	label: 'Fiction Film',
																	value: 'Fiction Film',
																	selected: User.current().experience == 'Fiction Film' ? 'selected' : null,
																},
																{
																	label: 'Documentary Film',
																	value: 'Documentary Film',
																	selected: User.current().experience == 'Documentary Film' ? 'selected' : null,
																},
																{
																	label: 'Equally both Fiction and Documentary film',
																	value: 'Equally both Fiction and Documentary film',
																	selected:
																		User.current().experience == 'Equally both Fiction and Documentary film'
																			? 'selected'
																			: null,
																},
																{
																	label: 'Not applicable (I don’t work in production or distribution)',
																	value: 'Not applicable (I don’t work in production or distribution)',
																	selected:
																		User.current().experience ==
																		'Not applicable (I don’t work in production or distribution)'
																			? 'selected'
																			: null,
																},
															],
														}),
														m(
															'p',
															'We may also require a mobile number for you to use for certain secure screener login processes for some distributors. If you are happy to share this with us and the distributors, please enter your mobile number here*'
														),
														m('.row', [
															m('.col-md-6.col-lg-4', [
																m(formGroup, {
																	label: 'Mobile',
																	type: 'textfield',
																	resource: User,
																	property: 'mobile',
																}),
															]),
														]),
														m(
															'p.text-muted.small',
															'*Your mobile number will only be used as part of the secure login process for certain online screening services, who sign data agreements with BIFA regarding the use of such information.'
														),
													],
												}),
												m('hr'),
												m(card, {
													// Step 3. Agreements
													header: 'Step 3. Agreements',
													body: [
														User.config() &&
															m(
																'p',
																m.trust(
																	`By ticking this box and registering to be a voter for the British Independent Film Awards 2024, all voters agree to abide by BIFA's Voter Agreement and Code of Conduct which can be found in full <a href='${
																		User.config().voterAgreementLink
																			? User.config().voterAgreementLink
																			: 'https://storage.googleapis.com/bifa-film/web/2024/05/BIFA-Voter-Code-of-Conduct-2024.pdf'
																	}' target='_blank'>here</a>.`
																)
															),

														m('.custom-control.custom-switch.custom-control-inline', [
															m('input.custom-control-input.custom-control-input-success', {
																type: 'checkbox',
																id: 'i-IsAgreeTerms',
																checked: User.current().isAgreeTerms,
																required: true,
																onchange: (e) => {
																	User.current().isAgreeTerms = (e.currentTarget || e.target).checked
																},
															}),
															m(
																'label.custom-control-label',
																{
																	for: 'i-IsAgreeTerms',
																},
																'I agree'
															),
														]),

														m('p.mt-4', 'Also, please confirm that'),
														m('div', [
															m('.custom-control.custom-switch.custom-control-inline', [
																m('input.custom-control-input.custom-control-input-success', {
																	type: 'checkbox',
																	id: 'i-IsBIFAContactConsent',
																	checked: User.current().isBIFAContactConsent,
																	onchange: (e) => {
																		User.current().isBIFAContactConsent = (e.currentTarget || e.target).checked
																	},
																}),
																m(
																	'label.custom-control-label',
																	{
																		for: 'i-IsBIFAContactConsent',
																	},
																	'I am happy to receive information about BIFA voting from BIFA using the details given above.'
																),
															]),
															// m('.custom-control.custom-switch.custom-control-inline', [
															// 	m('input.custom-control-input.custom-control-input-success', {
															// 		type: 'checkbox',
															// 		id: 'i-IsDistributorContactConsent',
															// 		checked: User.current().isDistributorContactConsent,
															// 		onchange: e => {
															// 			User.current().isDistributorContactConsent = (e.currentTarget || e.target).checked
															// 		},
															// 	}),
															// 	m(
															// 		'label.custom-control-label',
															// 		{
															// 			for: 'i-IsDistributorContactConsent',
															// 		},
															// 		'I am happy for BIFA to pass my contact details on to distributors who have signed data use agreements with BIFA, for the purposes of receiving screeners and invitations to screenings.'
															// 	),
															// ]),
															m('.custom-control.custom-switch.custom-control-inline', [
																m('input.custom-control-input.custom-control-input-success', {
																	type: 'checkbox',
																	id: 'i-IsBIFANewsletterConsent',
																	checked: User.current().isBIFANewsletterConsent,
																	onchange: (e) => {
																		User.current().isBIFANewsletterConsent = (e.currentTarget || e.target).checked
																	},
																}),
																m(
																	'label.custom-control-label',
																	{
																		for: 'i-IsBIFANewsletterConsent',
																	},
																	'I would like to receive the BIFA newsletter, to learn more about BIFA activities/events, partner offers, industry opportunities and the latest in independent film news.'
																),
															]),
															m('.custom-control.custom-switch.custom-control-inline', [
																m('input.custom-control-input.custom-control-input-success', {
																	type: 'checkbox',
																	id: 'i-IsBIFAResearch',
																	checked: User.current().isBIFAResearch,
																	onchange: (e) => {
																		User.current().isBIFAResearch = (e.currentTarget || e.target).checked
																	},
																}),
																m(
																	'label.custom-control-label',
																	{
																		for: 'i-IsBIFAResearch',
																	},
																	'I am happy for BIFA to use my data to run analyses and research on the demographics and state of the British independent film industry.'
																),
															]),
															m(
																'p.mt-3',
																m.trust(
																	"We've updated our privacy policies so that we're GDPR compliant. You can read our privacy policy regarding information you give to us for the purposes of voting for BIFA <a href='https://www.bifa.film/privacy-policy/voting/' target='_blank'>here</a>."
																)
															),
														]),
														m('.custom-control.custom-switch.custom-control-inline', [
															m('input.custom-control-input.custom-control-input-success', {
																type: 'checkbox',
																id: 'i-IsMoreEDICoursesInfo',
																checked: User.current().isMoreEDICoursesInfo,
																onchange: (e) => {
																	User.current().isMoreEDICoursesInfo = (e.currentTarget || e.target).checked
																},
															}),
															m(
																'label.custom-control-label',
																{
																	for: 'i-IsMoreEDICoursesInfo',
																},
																"I would like to receive more information about BIFA's Equality, Diversity & Inclusion training courses, including Anti-Bullying and Harassment Training, Unconscious Bias and Fair Recruitment and Inclusivity in the Workplace Training."
															),
														]),
													],
												}),
												m('hr'),

												m(card, {
													// Step 4. Email Consent
													header: 'Step 4. Consent',
													body: [
														m('p', 'Eventive'),
														m(
															'p',
															'BIFA uses Eventive as a screening platform for the awards. To access any BIFA film online on Eventive you must have a BIFA pass, which is allocated to you via the Eventive platform using your name and email address. If you used Eventive to view BIFA films online in 2024 and the email address you have provided above is unchanged, your BIFA pass will be added to your Eventive account and you will be notified of this by email. If you do not have an Eventive account, you will be prompted to create one when your pass is issued.'
														),
														m('.custom-control.custom-switch.custom-control-inline.custom-control-input-success', [
															m('input.custom-control-input', {
																type: 'radio',
																id: 'i-eventiveconsentyes',
																name: 'ecc',
																checked: User.current().isAgreeEventive,
																required: true,
																onchange: (e) => {
																	User.current().isAgreeEventive = (e.currentTarget || e.target).checked
																	//VoterFilm.save()
																},
															}),
															m(
																'label.custom-control-label',
																{
																	for: 'i-eventiveconsentyes',
																},
																'I CONSENT to BIFA using my name and email address as provided to allocate my BIFA pass on Eventive'
															),
														]),
														m('.custom-control.custom-switch.custom-control-inline', [
															m('input.custom-control-input', {
																type: 'radio',
																id: 'i-eventiveconsentno',
																name: 'ecc',
																checked: User.current().isAgreeEventive === false,
																required: true,
																onchange: (e) => {
																	User.current().isAgreeEventive = !(e.currentTarget || e.target).checked
																	//VoterFilm.save()
																},
															}),
															m(
																'label.custom-control-label',
																{
																	for: 'i-eventiveconsentno',
																},
																'I DO NOT CONSENT to BIFA using my name and email address as provided to allocate my BIFA pass on Eventive'
															),
														]),
														m(
															'p.font-italic',
															m(
																'a',
																{ href: 'https://eventive.org/privacy', target: '_blank' },
																"If you would like to read Eventive's privacy policy click here"
															)
														),
														m('p.mt-4', 'Screener Requests'),
														m(
															'p',
															'Occasionally we need to send the contact details you provide to distributors for the purpose of providing you with individual access or links to films in contention in the awards.'
														),
														m('.custom-control.custom-switch.custom-control-inline.custom-control-input-success', [
															m('input.custom-control-input', {
																type: 'radio',
																id: 'i-emailconsentyes',
																name: 'ec',
																checked: User.current().isDistributorContactConsent,
																required: true,
																onchange: (e) => {
																	User.current().isDistributorContactConsent = (e.currentTarget || e.target).checked
																	//VoterFilm.save()
																},
															}),
															m(
																'label.custom-control-label',
																{
																	for: 'i-emailconsentyes',
																},
																'I CONSENT to BIFA passing my contact details (email address, name, phone number) on to distributors, for the purposes of receiving screeners and invitations to screenings'
															),
														]),
														m('.custom-control.custom-switch.custom-control-inline', [
															m('input.custom-control-input', {
																type: 'radio',
																id: 'i-emailconsentno',
																name: 'ec',
																checked: User.current().isDistributorContactConsent === false,
																required: true,
																onchange: (e) => {
																	User.current().isDistributorContactConsent = !(e.currentTarget || e.target).checked
																	//VoterFilm.save()
																},
															}),
															m(
																'label.custom-control-label',
																{
																	for: 'i-emailconsentno',
																},
																'I DO NOT CONSENT to BIFA passing my contact details (email address, name, phone number) on to distributors, for the purposes of receiving screeners and invitations to screenings'
															),
														]),
														m(
															'p.mt-3',
															'For strictly the above purpose only, occasionally this data needs to be sent outside of the UK, including to the US.'
														),
														m('.custom-control.custom-switch.custom-control-inline.custom-control-input-success', [
															m('input.custom-control-input', {
																type: 'radio',
																id: 'i-emailconsentusyes',
																name: 'ecus',
																checked: User.current().isDistributorContactOutsideUKConsent,
																required: true,
																onchange: (e) => {
																	User.current().isDistributorContactOutsideUKConsent = (
																		e.currentTarget || e.target
																	).checked
																	//VoterFilm.save()
																},
															}),
															m(
																'label.custom-control-label',
																{
																	for: 'i-emailconsentusyes',
																},
																'I CONSENT to my contact details (email address, name, phone number) being sent to distributors, where necessary, outside of the UK, including to the US'
															),
														]),
														m('.custom-control.custom-switch.custom-control-inline', [
															m('input.custom-control-input', {
																type: 'radio',
																id: 'i-emailconsentusno',
																name: 'ecus',
																checked: User.current().isDistributorContactOutsideUKConsent === false,
																required: true,
																onchange: (e) => {
																	User.current().isDistributorContactOutsideUKConsent = !(e.currentTarget || e.target)
																		.checked
																	//VoterFilm.save()
																},
															}),
															m(
																'label.custom-control-label',
																{
																	for: 'i-emailconsentusno',
																},
																'I DO NOT CONSENT to my contact details (email address, name, phone number) being sent to distributors, where necessary, outside of the UK, including to the US'
															),
														]),
														m(
															'p.font-italic',
															'Every distributor who handles your data is required to agree to handle voter data in line with the principles of GDPR.  Distributors will dispose of your information once awards season is over.'
														),
														m(
															'p.font-italic',
															'Please be advised that not giving consent will mean that we may not always be able to give you access to the films you wish to view.'
														),
														m(
															'p.font-italic',
															'You may withdraw your consent at any time (by emailing hello@bifa.film)'
														),
													],
												}),
												m('hr'),

												m(card, {
													// Step 5. Conflicts of interest
													header: 'Step 5. Conflicts of interest',
													body: [
														m(
															'p',
															'As part of the agreement to register to vote for BIFA, we ask you to declare any conflict of interest you might have. We consider a COI to be:'
														),
														m('ul', [
															m('li', 'if you worked on a film directly and have a credit'),
															m('li', 'currently are employed by a company who funded or distributed the film'),
															m('li', 'have a partner or relative who worked on the film.'),
														]),
														m(
															'p.font-weight-bold',
															'If you recognise a name or are acquainted with that individual, this does not necessarily constitute a COI. Of course, if you feel you can’t be impartial, please mark it as a conflict when voting.'
														),
														m('p', 'At this stage, could you please just let us know the following:'),
														m(formGroup, {
															label: 'Your current employer/employers',
															type: 'textarea',
															resource: User,
															property: 'currentEmployers',
															required: false,
															rows: 3,
															classname: 'mb-0',
														}),
														m('p.small', 'This can include in either full-time, part-time or freelance capacities.'),
														m(formGroup, {
															label: 'Productions you have worked on in any capacity, in the last two years.',
															type: 'textarea',
															resource: User,
															property: 'productionsLast2Years',
															required: false,
															rows: 3,
														}),
														m(formGroup, {
															label: 'If you have one, please provide your IMDB or Linkedin profile link',
															type: 'textfield',
															resource: User,
															property: 'imdbLinkedIn',
															required: false,
														}),
													],
												}),
												m('hr'),

												m(card, {
													// Step 6. Unconscious Bias Training
													header: 'Step 6. Unconscious Bias Training',
													body: [
														m(
															'p',
															"As a BIFA voter, you're required to attend our Unconscious Bias training every three voting years. If you did the training in 2021, or have not yet done the training, please ensure you register for a session."
														),
														m(
															'label.w-50.mb-0.pr-3',
															{ for: `sel_ub` },
															'Please select when you last did the training:'
														),
														m('p', { style: 'max-width: 320px' }, [
															m(
																'select',
																{
																	id: `sel_ub`,
																	key: `sel_ub`,
																	name: `sel_ub`,
																	className: 'form-control',
																	required: true,
																	onchange: (evt) => {
																		User.current().unconsciousBiasStatus = (evt.currentTarget || evt.target).value
																		User.current().isUnconsciousBiasTrainingComplete =
																			User.current().unconsciousBiasStatus === '2021 voting year' ||
																			User.current().unconsciousBiasStatus === '2022 voting year' ||
																			User.current().unconsciousBiasStatus === '2023 voting year'
																	},
																},
																[
																	m('option', { value: '' }, 'Select...'),
																	m(
																		'option',
																		{
																			value: '2019 voting year',
																			selected:
																				User.current().unconsciousBiasStatus === '2019 voting year' ? 'selected' : null,
																		},
																		'2019 voting year'
																	),
																	m(
																		'option',
																		{
																			value: '2020 voting year',
																			selected:
																				User.current().unconsciousBiasStatus === '2020 voting year' ? 'selected' : null,
																		},
																		'2020 voting year'
																	),
																	m(
																		'option',
																		{
																			value: '2021 voting year',
																			selected:
																				User.current().unconsciousBiasStatus === '2021 voting year' ? 'selected' : null,
																		},
																		'2021 voting year'
																	),
																	m(
																		'option',
																		{
																			value: '2022 voting year',
																			selected:
																				User.current().unconsciousBiasStatus === '2022 voting year' ? 'selected' : null,
																		},
																		'2022 voting year'
																	),
																	m(
																		'option',
																		{
																			value: '2023 voting year',
																			selected:
																				User.current().unconsciousBiasStatus === '2023 voting year' ? 'selected' : null,
																		},
																		'2023 voting year'
																	),
																	m('option', { value: 'Not sure' }, 'Not sure'),
																	m('option', { value: 'Never' }, 'Never'),
																]
															),
														]),
													],
												}),
												m('hr'),

												m(card, {
													// Step 7. Diversity & Inclusion Monitoring
													header: 'Step 7. Diversity & Inclusion Monitoring',
													body: [
														User.survey() && m.trust(User.survey().description),
														User.current().surveyAnswersDI.survey &&
															m('ol.list-group.col-lg-9', [
																map(User.current().surveyAnswersDI.survey.questions, (question) => {
																	return question.visibleQuestionId === null || isFormAnswerMatched(question)
																		? m(
																				'li.list-group-item.p-2',
																				{
																					className:
																						question.visiblequestionId &&
																						!(
																							vnode.state.answers[question.visiblequestionId] ===
																							question.visibleQuestionValue
																						)
																							? 'd-none'
																							: '',
																				},
																				[
																					(question.questionType.id === 3 || question.questionType.id === 5) &&
																						m('.form-group.mb-0.d-flex', [
																							m('label.w-50.mb-0.pr-3', { for: `sel_q_${question.questionId}` }, [
																								question.name,
																								question.isRequired && ' *',
																							]),
																							m('select', {
																								id: `sel_${question.questionId}`,
																								key: `sel_${question.questionId}`,
																								name: `sel_${question.questionId}`,
																								className: 'choices__input is-hidden',
																								multiple: question.questionType.id === 5,
																								oncreate: (el) => {
																									const choices = new Choices(el.dom, {
																										searchEnabled: false,
																										shouldSort: true,
																										removeItemButton: true,
																										choices: map(JSON.parse(question.options), (option) => {
																											return {
																												value: option,
																												label: option,
																												selected:
																													question.questionType.id === 5
																														? getFormAnswer(question.questionId).includes(option)
																														: option === getFormAnswer(question.questionId),
																											}
																										}),
																									})
																								},
																								onchange: (evt) => {
																									if (question.questionType.id === 5) {
																										const value = evt.detail.value
																										console.log(vnode.state.answers)

																										if (vnode.state.answers[question.questionId]) {
																											if (
																												vnode.state.answers[question.questionId].includes(
																													evt.detail.value
																												)
																											) {
																												vnode.state.answers[question.questionId].splice(
																													vnode.state.answers[question.questionId].indexOf(
																														evt.detail.value
																													),
																													1
																												)
																											} else {
																												vnode.state.answers[question.questionId].push(evt.detail.value)
																											}
																										} else {
																											vnode.state.answers[question.questionId] = [evt.detail.value]
																										}
																									} else {
																										vnode.state.answers[question.questionId] = (
																											evt.currentTarget || evt.target
																										).value
																									}

																									setFormAnswer(question, vnode.state.answers[question.questionId])
																									m.redraw()
																								},
																							}),
																						]),
																					question.questionType.id === 1 &&
																						m('.form-group.mb-0.d-flex', [
																							m('label.w-50.mb-0.pr-3', { for: `sel_q_${question.questionId}` }, [
																								question.name,
																								question.isRequired && ' *',
																							]),
																							m('input.form-control.mb-0', {
																								id: `sel_q_${question.questionId}`,
																								type: 'text',
																								name: `sel_q_${question.questionId}`,
																								required:
																									question.isRequired &&
																									!(
																										question.visiblequestionId &&
																										!(
																											vnode.state.answers[question.visiblequestionId] ===
																											question.visibleQuestionValue
																										)
																									),
																								onkeyup: (event) => {
																									vnode.state.answers[question.questionId] = (
																										event.currentTarget || event.target
																									).value
																									setFormAnswer(question, vnode.state.answers[question.questionId])
																								},
																								onchange: (event) => {
																									vnode.state.answers[question.questionId] = (
																										event.currentTarget || event.target
																									).value
																									setFormAnswer(question, vnode.state.answers[question.questionId])
																								},
																								value: getFormAnswer(question.questionId),
																							}),
																						]),
																					question.questionType.id === 2 &&
																						m('.form-group.mb-0.d-flex', [
																							m('label.w-50.mb-0.pr-3', { for: `sel_q_${question.questionId}` }, [
																								question.name,
																								question.isRequired && ' *',
																							]),
																							m('textarea.form-control.mb-0', {
																								id: `sel_q_${question.questionId}`,
																								name: `sel_q_${question.questionId}`,
																								rows: '4',
																								required:
																									question.isRequired &&
																									!(
																										question.visiblequestionId &&
																										!(
																											vnode.state.answers[question.visiblequestionId] ===
																											question.visibleQuestionValue
																										)
																									),
																								onkeyup: (event) => {
																									vnode.state.answers[question.questionId] = (
																										event.currentTarget || event.target
																									).value
																									setFormAnswer(question, vnode.state.answers[question.questionId])
																								},
																								onchange: (event) => {
																									vnode.state.answers[question.questionId] = (
																										event.currentTarget || event.target
																									).value
																									setFormAnswer(question, vnode.state.answers[question.questionId])
																								},
																								value: getFormAnswer(question.questionId),
																							}),
																						]),
																				]
																		  )
																		: null
																}),
															]),
													],
												}),
												m('hr'),
											]),

										m(card, {
											header: 'Save',
											body: [
												m('p', 'Please click "Save changes" to update your settings'),
												m('.row', [m('.col-sm-6.col-md-3.col-lg-2', m(saveBtnSubmit, { resource: User }))]),
											],
										}),
								  ]),
						]),
					]
				),
		  ]
		: m(loading)
}

function getChoices(votes, ix) {
	return map(Subgroup.list(), (sg) => {
		return {
			value: sg.id,
			label: sg.Name,
			selected: find(votes, (vote) => {
				return vote.subgroupId === sg.id && vote.Preference === ix + 1
			})
				? true
				: false,
			disabled: find(votes, (vote) => {
				return vote.subgroupId === sg.id && vote.Preference !== ix + 1
			})
				? true
				: false,
		}
	})
}
export default pageUser
