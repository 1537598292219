﻿import m from 'mithril'
import prop from 'mithril/stream'
import Account from '../models/Account'
import notifications from '../components/notifications'
import loading from '../components/loading'
import { card } from 'bootstrap-mithril'
import setValue from '../helpers/setValue'

const form = {}

form.oninit = (vnode) => {
	vnode.state.loading = prop(false)
	let code = vnode.attrs.code
	if (!code && window.location.search) {
		code = new URLSearchParams(window.location.search).get('code')
	}
	vnode.state.dto = {
		resetCode: code,
		usernameOrEmail: vnode.attrs.email ? vnode.attrs.email : '',
		password: '',
	}

	vnode.state.submitForm = (e) => {
		e.preventDefault()
		vnode.state.loading(true)
		Account.resetPassword(vnode.state.dto)
			.then(() => {
				m.route.set('/')
				notifications.add({
					title: 'Your password has been changed',
					message: 'You should be able to log in now!',
				})
			})
			.catch((e) => {
				vnode.state.loading(false)
				return e
					? notifications.add({
							type: 'Error',
							title: 'Reset Password Issue',
							message: e.error_description,
					  })
					: null
			})
	}
}

form.view = (vnode) => {
	return (
		vnode.state.dto &&
		m(card, {
			classes: ['login-form', 'mt-5'],
			header: 'Reset Password',
			body: m(
				'form',
				{
					onsubmit: vnode.state.submitForm,
				},
				[
					m('p', 'Passwords must be 8 characters minimum, including at least 1 uppercase, lowercase and digit'),
					m('.form-group', [
						m(
							'label',
							{
								for: 'email',
							},
							'Email'
						),
						m('input.form-control', {
							id: 'email',
							type: 'email',
							onchange: setValue(vnode.state.dto, 'usernameOrEmail'),
							value: vnode.state.dto.usernameOrEmail,
						}),
					]),
					m('.form-group', [
						m(
							'label',
							{
								for: 'password',
							},
							'New Password'
						),
						m('input.form-control', {
							id: 'password',
							type: 'password',
							onchange: setValue(vnode.state.dto, 'password'),
							value: vnode.state.dto.password,
							autocomplete: 'new-password',
							required: true,
							pattern: '(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$',
							title:
								'Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters',
						}),
					]),
					vnode.state.loading()
						? m(loading)
						: m(
								'button.btn.btn-primary',
								{
									type: 'submit',
								},
								['Submit']
						  ),
				]
			),
		})
	)
}

export default form
