﻿// import './styles/styles.css'
import './jquery-global.js'
import 'bootstrap'
import m from 'mithril'
import prop from 'mithril/stream'

import layout from './components/layout'

// Helpers
import redirectUnauthorised from './helpers/redirectunauthorised'
import routeCheck from './helpers/routeCheck'

// Models
import Account from './models/Account'
import Round from './models/Round'

// Pages
import navigation from './pages/navigation'
import accountNavigation from './account/navigation'
import pageForgottenPassword from './pages/pageForgottenPassword'
import pageResetPassword from './pages/pageResetPassword'
import pageDashboard from './pages/pageDashboard'
import pageUser from './pages/pageUser'
import pageVotingSettings from './pages/pageVotingSettings'
import pageUserChangePassword from './pages/pageUserChangePassword'
import pageLogin from './pages/pageLogin'
import pageFilm from './pages/pageFilm'
import pageViewing from './pages/pageViewing'
import pageScreenings from './pages/pageScreenings'
import pageMembership from './pages/pageMembership'
import pageUBTraining from './pages/pageUBTraining'
import pageRound from './pages/pageRound'
import pageRoundVotingCategory from './pages/pageRoundVotingCategory'

var isUnsavedChanges = prop(false)
var unsavedState = prop({})
const siteName = 'BIFA Voting'

m.route.prefix('')
m.route(document.getElementById('app'), '/', {
	'/': {
		onmatch: () => {
			return routeCheck(isUnsavedChanges, unsavedState).then(() => {
				document.title = `Dashboard - ${siteName}`
				Round.clear()
			})
		},
		render: () =>
			m(
				layout,
				{
					sidebar: m(navigation, { user: Account.user }),
				},
				m(pageDashboard, { user: Account.user })
			),
	},
	'/viewing/:subgroup:': {
		onmatch: () => {
			return routeCheck(isUnsavedChanges, unsavedState)
				.then(() => {
					document.title = `Viewing Films - ${siteName}`
					Round.clear()
				})
				.catch(console.log)
		},
		render: (vnode) =>
			m(
				layout,
				{
					sidebar: m(navigation, { user: Account.user }),
				},
				m(pageViewing, { user: Account.user, subgroup: vnode.attrs.subgroup })
			),
	},
	'/films/:id:': {
		onmatch: () => {
			return routeCheck(isUnsavedChanges, unsavedState).then(() => {
				document.title = `Film - ${siteName}`
			})
		},
		render: (vnode) =>
			m(
				layout,
				{
					sidebar: m(navigation, { user: Account.user }),
				},
				m(pageFilm, {
					user: Account.user,
					key: vnode.attrs.id,
					id: vnode.attrs.id,
					isUnsavedChanges: isUnsavedChanges,
					unsavedState: unsavedState,
				})
			),
	},
	'/rounds/:id:/category/:rvc:': {
		onmatch: () => {
			return routeCheck(isUnsavedChanges, unsavedState).then(() => {
				document.title = `Rounds - ${siteName}`
			})
		},
		render: (vnode) =>
			m(
				layout,
				{
					sidebar: m(navigation, { user: Account.user }),
				},
				m(pageRoundVotingCategory, {
					user: Account.user,
					key: vnode.attrs.rvc,
					id: vnode.attrs.id,
					rvc: vnode.attrs.rvc,
					isUnsavedChanges: isUnsavedChanges,
					unsavedState: unsavedState,
				})
			),
	},
	'/rounds/:id:/:tab:': {
		onmatch: () => {
			return routeCheck(isUnsavedChanges, unsavedState).then(() => {
				document.title = `Rounds - ${siteName}`
			})
		},
		render: (vnode) =>
			m(
				layout,
				{
					sidebar: m(navigation, { user: Account.user }),
				},
				m(pageRound, {
					user: Account.user,
					key: vnode.attrs.id,
					id: vnode.attrs.id,
					tab: vnode.attrs.tab,
				})
			),
	},
	'/rounds/:id:': {
		onmatch: () => {
			return routeCheck(isUnsavedChanges, unsavedState).then(() => {
				document.title = `Rounds - ${siteName}`
			})
		},
		render: (vnode) =>
			m(
				layout,
				{
					sidebar: m(navigation, { user: Account.user }),
				},
				m(pageRound, {
					user: Account.user,
					key: vnode.attrs.id,
					id: vnode.attrs.id,
					tab: 'subgroups',
				})
			),
	},
	'/screenings': {
		onmatch: () => {
			return routeCheck(isUnsavedChanges, unsavedState).then(() => {
				document.title = `Screenings - ${siteName}`
				Round.clear()
			})
		},
		render: (vnode) =>
			m(
				layout,
				{
					sidebar: m(navigation, { user: Account.user }),
				},
				m(pageScreenings, { user: Account.user })
			),
	},
	'/login': {
		onmatch: function (args, path) {
			var route = this
			if (Account.user() && localStorage.getItem('accessToken')) {
				m.route.set('/')
			} else if (path !== m.route.get()) {
				Account.user(null)
				route.id = args.id
				document.title = `Login - ${siteName}`
			}
		},
		render: function () {
			return m(layout, m(pageLogin))
		},
	},
	'/membership': {
		onmatch: () => {
			return routeCheck(isUnsavedChanges, unsavedState).then(() => {
				document.title = `Membership - ${siteName}`
				Round.clear()
			})
		},
		render: () => m(layout, { className: 'membership' }, m(pageMembership, { user: Account.user })),
	},
	'/me': {
		onmatch: () => {
			return routeCheck(isUnsavedChanges, unsavedState).then(() => {
				document.title = `Account - ${siteName}`
			})
		},
		render: () =>
			m(
				layout,
				{
					sidebar: m(accountNavigation),
				},
				m(pageUser, { user: Account.user, isUnsavedChanges: isUnsavedChanges, unsavedState: unsavedState })
			),
	},
	'/me/voting': {
		onmatch: () => {
			return routeCheck(isUnsavedChanges, unsavedState).then(() => {
				document.title = `Voting Preferences - ${siteName}`
			})
		},
		render: () =>
			m(
				layout,
				{
					sidebar: m(accountNavigation),
				},
				m(pageVotingSettings, { user: Account.user, isUnsavedChanges: isUnsavedChanges, unsavedState: unsavedState })
			),
	},
	'/me/ubtraining': {
		onmatch: () => {
			return routeCheck(isUnsavedChanges, unsavedState).then(() => {
				document.title = `Unconscious Bias Training - ${siteName}`
			})
		},
		render: () =>
			m(
				layout,
				{
					sidebar: m(accountNavigation),
				},
				m(pageUBTraining, { user: Account.user, isUnsavedChanges: isUnsavedChanges, unsavedState: unsavedState })
			),
	},
	'/me/changepassword': {
		onmatch: () => {
			return routeCheck(isUnsavedChanges, unsavedState).then(() => {
				document.title = `Change Password - ${siteName}`
			})
		},
		render: () =>
			m(
				layout,
				{
					sidebar: m(accountNavigation),
				},
				m(pageUserChangePassword, { user: Account.user })
			),
	},
	'/me/changepasswordexpired': {
		onmatch: () => {
			return routeCheck(isUnsavedChanges, unsavedState).then(() => {
				document.title = `Change Password - ${siteName}`
			})
		},
		render: () =>
			m(
				layout,
				{
					sidebar: m(accountNavigation),
				},
				m(pageUserChangePassword, { user: Account.user, expired: true })
			),
	},

	'/forgottenpassword': {
		onmatch: () => {
			document.title = `Forgotten Password - ${siteName}`
		},
		render: function () {
			return m(layout, m(pageForgottenPassword))
		},
	},
	'/forgottenpasswordsuccess': {
		render: function () {
			return m(layout, m('h3.text-center.m-5', 'Please check you email for reset link'))
		},
	},
	'/resetpassword': {
		onmatch: () => {
			document.title = `Reset Password - ${siteName}`
		},
		render: (vnode) => {
			return m(layout, m(pageResetPassword, vnode.attrs))
		},
	},
	'/reset-password': {
		onmatch: () => {
			document.title = `Reset Password - ${siteName}`
		},
		render: (vnode) => {
			return m(layout, m(pageResetPassword, vnode.attrs))
		},
	},
	'/set-password': {
		onmatch: () => {
			document.title = `Reset Password - ${siteName}`
		},
		render: (vnode) => {
			return m(layout, m(pageResetPassword, vnode.attrs))
		},
	},
})
